import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { confirmMember, loginMember } from "../../../services/login-services";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import Icon from "../../icon";

interface ConfirmationPageProps {}

interface TouchedFields {
  password?: boolean;
  repeatPassword?: boolean;
  requirements?: boolean;
}

interface FormFields {
  password: string;
  repeatPassword: string;
  requirements: boolean;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = () => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    repeatPassword: "",
    requirements: false,
  });
  const [errors, setErrors] = useState({} as any);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken(new URLSearchParams(window.location.search).get("t"));
    }
  }, []);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ password: false, repeatPassword: false, requirements: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.type) {
      case "checkbox":
        setFormValues({ ...formValues, [event.target.name]: event.target.checked });
        setTouched({
          ...touched,
          [event.target.name]: true,
        });
        break;
      default:
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
        break;
    }

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!formValues.password || formValues.password.length === 0) {
        errors = {
          ...errors,
          password: translate(translations, language, "ERROR_PASSWORD_REQUIRED"),
        };
      }
      if (!formValues.repeatPassword || formValues.repeatPassword.length === 0) {
        errors = {
          ...errors,
          repeatPassword: translate(translations, language, "ERROR_PASSWORD_REQUIRED"),
        };
      } else {
        if (formValues.password !== formValues.repeatPassword) {
          errors = {
            ...errors,
            repeatPassword: translate(translations, language, "ERROR_PASSWORD_MATCH"),
          };
        }
      }
      if (!formValues.requirements) {
        errors = {
          ...errors,
          requirements: translate(translations, language, "ERROR_AGREE_REQUIRED"),
        };
      }

      if (!token || token.length === 0) {
        errors = {
          ...errors,
          token: translate(translations, language, "ERROR_TOKEN_REQUIRED"),
        };
      }
    }

    setErrors(errors);
  };

  const handleConfirmation = async () => {
    if (token) {
      try {
        const response = await confirmMember(token, formValues.password, formValues.requirements);
        if (response.ok) {
          setIsConfirmed(true);
        }
      } catch {
        setErrors({
          ...errors,
          api: translate(translations, language, "ERROR_401"),
        });
      }
    }
  };

  useEffect(() => {
    validate();
  }, [formValues, token]);

  const showErrors = () => {
    return (
      (errors.password && touched?.password) ||
      (errors.repeatPassword && touched?.repeatPassword) ||
      (errors.requirements && touched?.requirements) ||
      errors.token ||
      errors.api
    );
  };

  return (
    <>
      {!isConfirmed && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Bevestig uw account</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className={`form__group form__group--100 form__group--md-100 ${touched?.password && errors.password ? "form__group--error" : ""}`}>
                <label className="form__label form__label--has-tooltip">Wachtwoord</label>
                <input
                  type="password"
                  className="form__input"
                  name="password"
                  value={formValues.password}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Wachtwoord"
                  required
                />
              </div>
            </div>
            <div className="form__row">
              <div
                className={`form__group form__group--100 form__group--md-100 ${
                  touched?.repeatPassword && errors.repeatPassword ? "form__group--error" : ""
                }`}
              >
                <label className="form__label form__label--has-tooltip">Herhaal wachtwoord</label>
                <input
                  type="password"
                  className="form__input"
                  name="repeatPassword"
                  value={formValues.repeatPassword}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Herhaal wachtwoord"
                  required
                />
              </div>
            </div>
            <div className="form__row">
              <div
                className={`form__group form__group--100 form__group--md-100 ${
                  touched?.requirements && errors.requirements ? "form__group--error" : ""
                }`}
              >
                <label className="slider">
                  <input type="checkbox" className="slider__input" name="requirements" onChange={(event) => onChange(event)} required />
                  <span className="slider__text">
                    {" "}
                    Ik ga akkoord met{" "}
                    <a href="/voorwaarden" target="_blank">
                      de algemene voorwaarden
                    </a>
                  </span>
                </label>
              </div>
            </div>
            {Object.keys(errors).length > 0 && showErrors() && (
              <div className="form__row">
                <div className="form__group form__group--100 form__group--md-100">
                  <div className="form__errors">
                    <div className="form__errors-group">
                      <ul>
                        {errors.password && touched?.password && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.password)}>
                              {translate(translations, language, errors.password)}
                            </a>
                          </li>
                        )}
                        {errors.repeatPassword && touched?.repeatPassword && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.repeatPassword)}>
                              {translate(translations, language, errors.repeatPassword)}
                            </a>
                          </li>
                        )}
                        {errors.requirements && touched?.requirements && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.requirements)}>
                              {translate(translations, language, errors.requirements)}
                            </a>
                          </li>
                        )}
                        {errors.token && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.token)}>
                              {translate(translations, language, errors.token)}
                            </a>
                          </li>
                        )}
                        {errors.api && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.api)}>
                              {translate(translations, language, errors.api)}
                            </a>
                          </li>
                        )}
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form__row actions">
              <button
                disabled={Object.keys(errors).length > 0}
                type="button"
                title="Bevestig uw account"
                className="cta cta--submit"
                onClick={() => handleConfirmation()}
              >
                Bevestig uw account
              </button>
            </div>
          </div>
        </div>
      )}
      {isConfirmed && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Account bevestigd</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className="form__group">
                <div className="login__status-message">
                  <p>Jouw account is nu actief. Klik hieronder om in te loggen met je gegevens.</p>
                </div>
              </div>
            </div>
            <div className="form__row actions">
              <button type="button" title="Terug naar login" className="cta cta--submit" onClick={() => navigate("/login")}>
                Naar de login
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationPage;
