import { graphql, PageProps } from "gatsby";
import { find, isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import Seo from "../../components/seo";
import { TideItemForWebsiteConnection } from "../../types";
import { parse } from "../../utils/component";
import GlobalContext from "../../contexts/global-provider";
import NotificationBar from "../../components/notification-bar";
import Footer from "../../components/footer";
import Login from "../../components/login";
import { Helmet } from "react-helmet";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

export interface LoginPageData {
  websites: TideItemForWebsiteConnection;
}
interface LoginContext {
  websiteName: string;
  isLoginPage: boolean;
  isMemberConfirmation: boolean;
  isResetPassword: boolean;
}

const LoginPageTemplate: React.FC<PageProps<LoginPageData, LoginContext>> = ({
  data,
  pageContext: { websiteName, isLoginPage, isMemberConfirmation, isResetPassword },
}) => {
  const { language } = useContext(GlobalContext);

  const globalContext = useContext(GlobalContext);
  const { notificationBar } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    if (language == "" && globalContext) {
      globalContext.setLanguage("nl-BE");
    }
  }, [globalContext]);

  const website = find(data.websites.nodes, { name: websiteName, language: language ? language : "nl-BE" });

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo title={`Login ${websiteName}`} description={`Login ${websiteName}`} keywords={`Login ${websiteName}`} website={parse(website)} />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
        />
      )}
      <Navbar website={website} />
      <Login isLoginPage={isLoginPage} isMemberConfirmation={isMemberConfirmation} isResetPassword={isResetPassword}></Login>
      <Footer name={parse(website?.name)} logo={parse(website?.content?.general?.logo)} />
    </Layout>
  );
};

export default LoginPageTemplate;

export const query = graphql`
  query LoginPageQuery {
    websites: allTideItemForWebsite {
      nodes {
        name
        id
        language
        content {
          general {
            phone
            helptext
            linkSgr
            linkThuiswinkelWaarborg
            linkTelephone
            logo {
              altText
              url
              title
            }
            linkLogo
          }
        }
      }
    }
  }
`;
