import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { newPassword } from "../../../services/login-services";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import Icon from "../../icon";

interface ResetPasswordProps {}

interface TouchedFields {
  password?: boolean;
  repeatPassword?: boolean;
}

interface FormFields {
  password: string;
  repeatPassword: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const [passwordSet, setPasswordSet] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({} as any);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken(new URLSearchParams(window.location.search).get("t"));
    }
  }, []);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ password: false, repeatPassword: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!formValues.password || formValues.password.length === 0) {
        errors = {
          ...errors,
          password: translate(translations, language, "ERROR_PASSWORD_REQUIRED"),
        };
      }
      if (!formValues.repeatPassword || formValues.repeatPassword.length === 0) {
        errors = {
          ...errors,
          repeatPassword: translate(translations, language, "ERROR_PASSWORD_REQUIRED"),
        };
      } else {
        if (formValues.password !== formValues.repeatPassword) {
          errors = {
            ...errors,
            repeatPassword: translate(translations, language, "ERROR_PASSWORD_MATCH"),
          };
        }
      }

      if (!token || token.length === 0) {
        errors = {
          ...errors,
          token: translate(translations, language, "ERROR_TOKEN_REQUIRED"),
        };
      }
    }

    setErrors(errors);
  };

  const handleResetPassword = async () => {
    if (token) {
      try {
        const response = await newPassword(token, formValues.password);
        if (response) {
          setPasswordSet(true);
        }
      } catch {
        setErrors({
          ...errors,
          api: translate(translations, language, "ERROR_401"),
        });
      }
    }
  };

  useEffect(() => {
    validate();
  }, [formValues, token]);

  const showErrors = () => {
    return (errors.password && touched?.password) || (errors.repeatPassword && touched?.repeatPassword) || errors.token || errors.api;
  };

  return (
    <>
      {!passwordSet && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Kies een nieuw wachtwoord</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className={`form__group form__group--100 form__group--md-100 ${touched?.password && errors.password ? "form__group--error" : ""}`}>
                <label className="form__label form__label--has-tooltip">Wachtwoord</label>
                <input
                  type="password"
                  className="form__input"
                  name="password"
                  value={formValues.password}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Wachtwoord"
                  required
                />
              </div>
            </div>
            <div className="form__row">
              <div
                className={`form__group form__group--100 form__group--md-100 ${
                  touched?.repeatPassword && errors.repeatPassword ? "form__group--error" : ""
                }`}
              >
                <label className="form__label form__label--has-tooltip">Herhaal wachtwoord</label>
                <input
                  type="password"
                  className="form__input"
                  name="repeatPassword"
                  value={formValues.repeatPassword}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Herhaal wachtwoord"
                  required
                />
              </div>
            </div>
            {Object.keys(errors).length > 0 && touched && showErrors() && (
              <div className="form__row">
                <div className="form__group form__group--100 form__group--md-100">
                  <div className="form__errors">
                    <div className="form__errors-group">
                      <ul>
                        {errors.password && touched.password && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.password)}>
                              {translate(translations, language, errors.password)}
                            </a>
                          </li>
                        )}
                        {errors.repeatPassword && touched.repeatPassword && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.repeatPassword)}>
                              {translate(translations, language, errors.repeatPassword)}
                            </a>
                          </li>
                        )}
                        {errors.token && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.token)}>
                              {translate(translations, language, errors.token)}
                            </a>
                          </li>
                        )}
                        {errors.api && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.api)}>
                              {translate(translations, language, errors.api)}
                            </a>
                          </li>
                        )}
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form__row actions">
              <button
                disabled={Object.keys(errors).length > 0}
                type="button"
                title="Bevestig uw account"
                className="cta cta--submit"
                onClick={() => handleResetPassword()}
              >
                Bevestig uw wachtwoord
              </button>
            </div>
          </div>
        </div>
      )}
      {passwordSet && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Wachtwoord aangepast</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className="form__group">
                <div className="login__status-message">
                  <p>Jouw wachtwoord is aangepast. Klik hieronder om in te loggen met je nieuwe wachtwoord.</p>
                </div>
              </div>
            </div>
            <div className="form__row actions">
              <button type="button" title="Terug naar login" className="cta cta--submit" onClick={() => navigate("/login")}>
                Naar de login
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
