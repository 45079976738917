import React from "react";
import LoginPage from "./login-page";
import ConfirmationPage from "./confirm-page";
import ResetPassword from "./reset-password";

interface LoginProps {
  isMemberConfirmation: boolean;
  isLoginPage: boolean;
  isResetPassword: boolean;
}

const Login: React.FC<LoginProps> = ({ isMemberConfirmation, isLoginPage, isResetPassword }) => {
  return (
    <div className="login">
      <div className="login__container">
        <form className="form" id="agent-login">
          <div className="login-card">
            <div className="form__region">
              <div className="form__row">
                {isMemberConfirmation && <ConfirmationPage></ConfirmationPage>}
                {isLoginPage && <LoginPage></LoginPage>}
                {isResetPassword && <ResetPassword></ResetPassword>}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
