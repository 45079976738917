import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { loginMember, passwordForgotten } from "../../../services/login-services";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import Icon from "../../icon";
import { decodeTokenToMemberInfo } from "../../../utils/member-and-tide-utils";

interface LoginPageProps {}

interface TouchedFields {
  password?: boolean;
  userName?: boolean;
}

interface FormFields {
  password: string;
  userName: string;
}

const LoginPage: React.FC<LoginPageProps> = () => {
  const { language, member, setMember } = useContext<GlobalContextType>(GlobalContext);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [newPasswordRequested, setNewPasswordRequested] = useState<boolean>(false);
  const [touched, setTouched] = useState<TouchedFields>();
  const [formValues, setFormValues] = useState<FormFields>({
    password: "",
    userName: "",
  });

  const [errors, setErrors] = useState({} as any);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched({ userName: false, password: false });
    }
    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    validate();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    validate();
  };

  const validate = () => {
    let errors = {} as any;
    if (formValues) {
      if (!isForgotPassword) {
        if (!formValues.password || formValues.password.length === 0) {
          errors = {
            ...errors,
            password: translate(translations, language, "ERROR_PASSWORD_REQUIRED"),
          };
        }
      }

      if (!formValues.userName || formValues.userName.length === 0) {
        errors = {
          ...errors,
          userName: translate(translations, language, "ERROR_USERNAME_REQUIRED"),
        };
      }
    }

    setErrors(errors);
  };

  const handleLogin = async () => {
    try {
      const response = await loginMember(formValues.userName, formValues.password);
      const member = decodeTokenToMemberInfo(response.token);
      if (member && member.id !== 0) {
        setMember(member);
        if (typeof sessionStorage !== "undefined") {
          sessionStorage.setItem("token", response.token);
        }
        setIsLoggedIn(true);
      }
    } catch (error: any) {
      setErrors({
        ...errors,
        api: translate(translations, language, "ERROR_401"),
      });
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await passwordForgotten(formValues.userName);
      if (response) {
        setNewPasswordRequested(true);
      }
    } catch (error: any) {
      setErrors({
        ...errors,
        api: translate(translations, language, error.message),
      });
    }
  };

  useEffect(() => {
    validate();
  }, [formValues]);

  const showErrors = () => {
    return (errors.password && touched?.password) || (errors.userName && touched?.userName) || errors.api;
  };

  return (
    <>
      {!isLoggedIn && !isForgotPassword && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Aanmelden als Travel Agent</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className={`form__group form__group--100 form__group--md-100 ${touched?.userName && errors.userName ? "form__group--error" : ""}`}>
                <label className="form__label form__label--has-tooltip">Gebruikersnaam</label>
                <input
                  type="text"
                  className="form__input"
                  name="userName"
                  value={formValues.userName}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Gebruikersnaam"
                  required
                />
              </div>
            </div>
            <div className="form__row">
              <div className={`form__group form__group--100 form__group--md-100 ${touched?.password && errors.password ? "form__group--error" : ""}`}>
                <label className="form__label form__label--has-tooltip">Wachtwoord</label>
                <input
                  type="password"
                  className="form__input"
                  name="password"
                  value={formValues.password}
                  onChange={(event) => onChange(event)}
                  onBlur={(event) => handleBlur(event)}
                  placeholder="Wachtwoord"
                  required
                />
              </div>
            </div>
            {Object.keys(errors).length > 0 && touched && showErrors() && (
              <div className="form__row">
                <div className="form__group form__group--100 form__group--md-100">
                  <div className="form__errors">
                    <div className="form__errors-group">
                      <ul>
                        {errors.userName && touched.userName && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.userName)}>
                              {translate(translations, language, errors.userName)}
                            </a>
                          </li>
                        )}
                        {errors.password && touched.password && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.password)}>
                              {translate(translations, language, errors.password)}
                            </a>
                          </li>
                        )}
                        {errors.api && (
                          <li>
                            <a className="form__errors-anchor" title={translate(translations, language, errors.api)}>
                              {translate(translations, language, errors.api)}
                            </a>
                          </li>
                        )}
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form__row actions">
              <button disabled={Object.keys(errors).length > 0} type="button" title="Login" className="cta cta--submit" onClick={() => handleLogin()}>
                Login
              </button>
              <button type="button" title="Login" className="cta cta--link" onClick={() => setIsForgotPassword(true)}>
                Wachtwoord vergeten
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoggedIn && member && !isForgotPassword && (
        <div className="form__group">
          <div className="login-card__header">
            <Icon name="user" />
            <h1 className="login-card__heading">Welkom {member?.name}</h1>
          </div>
          <div className="login-card__body">
            <div className="form__row">
              <div className="form__group">
                <div className="login__status-message">
                  <p>Klik hieronder om terug te keren naar de homepage &amp; aan de slag te gaan.</p>
                </div>
              </div>
            </div>
            <div className="form__row actions">
              <button type="button" title="Terug naar login" className="cta cta--submit" onClick={() => navigate("/")}>
                Terug naar de homepage
              </button>
            </div>
          </div>
        </div>
      )}
      {isForgotPassword && (
        <>
          {!newPasswordRequested && (
            <div className="form__group">
              <div className="login-card__header">
                <Icon name="user" />
                <h1 className="login-card__heading">Wachtwoord vergeten?</h1>
                <p className="login-card__header-text">
                  Vul hieronder jouw gebruikersnaam in &amp; in enkele tellen ontvang je een link waarmee je een nieuw wachtwoord kunt instellen.
                </p>
              </div>
              <div className="login-card__body">
                <div className="form__row">
                  <div
                    className={`form__group form__group--100 form__group--md-100 ${touched?.userName && errors.userName ? "form__group--error" : ""}`}
                  >
                    <label className="form__label form__label--has-tooltip">Gebruikersnaam</label>
                    <input
                      type="text"
                      className="form__input"
                      name="userName"
                      value={formValues.userName}
                      onChange={(event) => onChange(event)}
                      onBlur={(event) => handleBlur(event)}
                      placeholder="E-mail"
                      required
                    />
                  </div>
                </div>
                <div className="form__row actions">
                  <button
                    disabled={Object.keys(errors).length > 0}
                    type="button"
                    title="Login"
                    className="cta cta--submit"
                    onClick={() => handleForgotPassword()}
                  >
                    Reset aanvragen
                  </button>
                </div>
              </div>
            </div>
          )}
          {newPasswordRequested && (
            <div className="form__group">
              <div className="login-card__header">
                <Icon name="user" />
                <h1 className="login-card__heading">Reset aangevraagd</h1>
              </div>
              <div className="login-card__body">
                <div className="form__row">
                  <div className="form__group">
                    <label className="status-message">
                      Je verzoek is succesvol ontvangen. Je ontvangt zodadelijk een e-mail om je wachtwoord te veranderen.
                    </label>
                  </div>
                </div>
                <div className="form__row actions">
                  <button type="button" title="Terug naar login" className="cta cta--submit" onClick={() => navigate("/")}>
                    Terug naar de homepage
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LoginPage;
